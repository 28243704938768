<div class="interestGroup-settings-wrapper d-flex flex-column">
  <div class="mt-2 d-flex flex-sm-row flex-xs-row flex-column min-height-0">
    <div class="me-2 pb-2">
      <mat-card class="mh-100">
        <mat-card-header class="d-flex flex-row justify-content-between">
          <mat-card-title
            *ngIf="
              getTranslation$('manageInterestGroups')
                | async as manageInterestGroupsLabel
            "
          >
            {{ manageInterestGroupsLabel }}
          </mat-card-title>
          <button
            mat-mini-fab
            color="primary"
            (click)="addInterestGroup()"
            class="clickable"
            class="me-2 ms-2"
          >
            <mat-icon> add </mat-icon>
          </button>
        </mat-card-header>
        <mat-card-content class="min-height-0 scrollbar overflow-auto">

          <mat-form-field
            class="search_input_wrapper"
            appearance="outline"
            floatLabel="always">
            <mat-label>
              {{ getTranslation$('search') | async }}
              {{ getTranslation$('groups') | async }}
            </mat-label>
            <input
              matInput
              [formControl]="filterCommunitiesControl"
              (keydown)="$event.stopPropagation()" 
              (input)="filterCommunities()"
            />
          </mat-form-field>

          <mat-action-list *ngIf="interestGroups" class="min-height-0">
            <mat-list-item
              [style.borderColor]="getColorControl(interestGroup).value"
              *ngFor="let interestGroup of filteredCommunities"
              [class.active]="
                getId(activeInterestGroup) === getId(interestGroup)
              "
              (click)="selectInterestGroup(interestGroup)"
            >
              <div
                class="colorStripe position-absolute start-0 top-0 h-100"
                style="width: 5px"
                [style.backgroundColor]="getColorControl(interestGroup).value"
              ></div>
              <div
                class="d-flex flex-row justify-content-between align-items-center"
              >
                <div class="text-truncate" [style.max-width.rem]="10">
                  {{ getTitle(interestGroup) }}
                </div>
                <div class="d-flex justify-content-center align-items-center">
                  <ng-container *ngIf="getIconUrlControl(interestGroup).value">
                    <img
                      class="list-icon m-2"
                      [src]="transform(getIconUrlControl(interestGroup).value)"
                      alt="Interest group icon"
                    />
                  </ng-container>
                  <ng-container
                    *ngIf="
                      isInterestGroupDeleted(interestGroup);
                      else interestGroupErrors
                    "
                  >
                    <mat-icon class="material-icons-outlined warn"
                      >delete</mat-icon
                    >
                  </ng-container>
                  <ng-template #interestGroupErrors>
                    <mat-icon
                      *ngIf="!isInterestGroupValid(interestGroup)"
                      class="material-icons-outlined"
                      >error_outline</mat-icon
                    >
                  </ng-template>
                </div>
              </div>
            </mat-list-item>
          </mat-action-list>
        </mat-card-content>
      </mat-card>
    </div>
    <div class="me-2 pb-2">
      <mat-card *ngIf="activeInterestGroup" class="position-relative mh-100">
        <button
          mat-icon-button
          [color]="
            isInterestGroupDeleted(activeInterestGroup) ? 'primary' : 'warn'
          "
          class="position-absolute delete-button"
          (click)="toggleDeleteInterestGroup(activeInterestGroup)"
        >
          <mat-icon class="material-icons-outlined">{{
            isInterestGroupDeleted(activeInterestGroup)
              ? "delete_forever"
              : "delete"
          }}</mat-icon>
        </button>
        <mat-card-header
          class="d-flex flex-row justify-content-between flex-shrink-0"
        >
          <mat-card-title
            *ngIf="
              getTranslation$('interestGroup') | async as interestGroupLabel
            "
          >
            {{ interestGroupLabel }}
          </mat-card-title>
        </mat-card-header>
        <mat-card-content class="min-height-0 d-flex">
          <div class="d-flex flex-column min-height-0">
            <mat-form-field
              class="ps-2 pe-2"
              appearance="outline"
              floatLabel="always"
            >
              <mat-label>
                <ng-container
                  *ngIf="getTranslation$('title') | async as titleLabel"
                  >{{ titleLabel }}
                </ng-container>
              </mat-label>
              <input
                #titleInterestGroup
                type="text"
                matInput
                [formControl]="getTitleControl(activeInterestGroup)"
              />
            </mat-form-field>
            <mat-form-field
              class="ps-2 pe-2"
              appearance="outline"
              floatLabel="always"
            >
              <mat-label>
                <ng-container
                  *ngIf="
                    getTranslation$('description') | async as descriptionLabel
                  "
                  >{{ descriptionLabel }}
                </ng-container>
              </mat-label>
              <textarea
                matInput
                [formControl]="getDescriptionControl(activeInterestGroup)"
              ></textarea>
            </mat-form-field>
            <div class="mx-auto pb-2">
              <ngx-colors
                ngx-colors-trigger
                [formControl]="getColorControl(activeInterestGroup)"
                format="hex"
              ></ngx-colors>
            </div>
            <div
              class="border-dashed border-gray rounded upload-zone m-2 d-flex flex-column align-items-center position-relative"
              (dragover)="dragStart($event)"
            >
              <div
                [hidden]="!dragging"
                class="position-absolute w-100 h-100 rounded drop-zone"
                (drop)="imageDropped($event, activeInterestGroup)"
                (dragleave)="dragStop($event)"
              ></div>
              <input
                #iconInput
                type="file"
                hidden
                accept="image/*"
                (change)="onIconInputChange($event, activeInterestGroup)"
              />
              <mat-icon class="material-icons-outlined upload-icon"
                >cloud_upload</mat-icon
              >
              <div
                class="d-flex flex-column flex-nowrap align-items-center m-2"
              >
                <div
                  *ngIf="
                    getTranslation$('dragAndDropYourFile')
                      | async as dragAndDropLabel
                  "
                >
                  {{ dragAndDropLabel }}
                </div>
                <div *ngIf="getTranslation$('OR') | async as orLabel">
                  {{ orLabel.toLowerCase() }}
                </div>
                <a
                  mat-raised-button
                  *ngIf="getTranslation$('browse') | async as browseLabel"
                  (click)="iconInput.click()"
                  color="primary"
                >
                  {{ browseLabel }}
                </a>
              </div>
            </div>
            <ng-container *ngIf="activeIconUrl">
              <div class="d-flex flex-row align-items-start">
                <img
                  class="icon m-2"
                  [src]="activeIconUrl"
                  alt="Interest group icon"
                />
                <button
                  mat-icon-button
                  (click)="clearIconInput(activeInterestGroup)"
                  class="clear-icon"
                >
                  <mat-icon class="material-icons-outlined">cancel</mat-icon>
                </button>
              </div>
            </ng-container>
            <div
              class="d-flex flex-row align-items-center justify-content-between ms-3 me-1 mb-2"
            >
              <div
                class="list-header"
                *ngIf="
                  getTranslation$('manageSubGroups') | async as subGroupsLabel
                "
              >
                {{ subGroupsLabel }}
              </div>
              <button
                mat-mini-fab
                color="primary"
                [matMenuTriggerFor]="addSubGroupMenu"
                class="clickable"
                class="me-2"
                [disabled]="possibleSubGroups.length === 0"
                [matTooltip]="
                  possibleSubGroups.length === 0
                    ? (getTranslation$('noSubGroupsToAdd') | async) ?? ''
                    : ''
                "
              >
                <mat-icon> add </mat-icon>
              </button>
            </div>
            <mat-menu #addSubGroupMenu="matMenu">
              <button
                *ngFor="
                  let subGroup of possibleSubGroups;
                  trackBy: groupTrackByFn
                "
                mat-menu-item
                (click)="addSubGroup(subGroup)"
              >
                {{ subGroup.title }}
              </button>
            </mat-menu>
            <div class="scrollbar overflow-auto">
              <mat-tree
                [dataSource]="dataSource"
                [treeControl]="treeControl"
                class="example-tree"
              >
                <!-- This is the tree node template for leaf nodes -->
                <!-- There is inline padding applied to this node using styles.
                  This padding value depends on the mat-icon-button width. -->
                <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle>
                  <div
                    class="d-flex flex-row justify-content-between align-items-center w-100 h-100"
                  >
                    <span>{{ node.name }}</span>
                    <button
                      class="hiddenWhenNested"
                      mat-icon-button
                      [color]="'warn'"
                      (click)="removeSubGroup(node.id)"
                    >
                      <mat-icon class="material-icons-outlined"
                        >delete</mat-icon
                      >
                    </button>
                  </div>
                </mat-tree-node>
                <!-- This is the tree node template for expandable nodes -->
                <mat-nested-tree-node
                  *matTreeNodeDef="let node; when: hasChild"
                >
                  <div class="mat-tree-node">
                    <div
                      class="d-flex flex-row justify-content-between align-items-center w-100 h-100"
                    >
                      <div class="d-flex align-items-center">
                        <button
                          mat-icon-button
                          matTreeNodeToggle
                          [attr.aria-label]="'Toggle ' + node.name"
                        >
                          <mat-icon class="mat-icon-rtl-mirror">
                            {{
                              treeControl.isExpanded(node)
                                ? "expand_more"
                                : "chevron_right"
                            }}
                          </mat-icon>
                        </button>
                        {{ node.name }}
                      </div>
                      <button
                        class="nestedHiddenWhenNested"
                        mat-icon-button
                        [color]="'warn'"
                        (click)="removeSubGroup(node.id)"
                      >
                        <mat-icon class="material-icons-outlined"
                          >delete</mat-icon
                        >
                      </button>
                    </div>
                  </div>
                  <!-- There is inline padding applied to this div using styles.
                        This padding value depends on the mat-icon-button width.  -->
                  <div
                    [class.example-tree-invisible]="
                      !treeControl.isExpanded(node)
                    "
                    role="group"
                  >
                    <ng-container matTreeNodeOutlet></ng-container>
                  </div>
                </mat-nested-tree-node>
              </mat-tree>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</div>
