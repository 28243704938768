import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, mergeMap, switchMap, tap } from 'rxjs/operators';
import { Ishtar365Service } from '../../services/ishtar365.service';
import {
  getFormTemplates,
  getFormTemplatesResolved,
} from './formTemplate.actions';

@Injectable({ providedIn: 'root' })
export class FormTemplateEffects {
  constructor(private actions$: Actions, private ishtar365: Ishtar365Service) {}

  getformTemplates = createEffect(() =>
    this.actions$.pipe(
      ofType(getFormTemplates),
      mergeMap(({ appName, callback, error }) =>
        this.ishtar365.getFormTemplates(appName).pipe(
          tap((formTemplates) =>
            callback ? callback(formTemplates) : undefined
          ),
          mergeMap((formTemplates) =>
            of(getFormTemplatesResolved({ appName, formTemplates }))
          ),
          catchError((e) => {
            if (error) error(e);
            return [];
          })
        )
      )
    )
  );
}
