<div class="d-flex flex-column gap-2 p-4" *ngIf="config">
  <div class="d-flex flex-column gap-1">
    <h3>Schedule</h3>
    <div class="configrow">
      <mat-checkbox
        [checked]="config.scheduleTimeEnabled"
        [(ngModel)]="config.scheduleTimeEnabled"
        (ngModelChange)="onChange()"
        >Only notify me from</mat-checkbox
      >
      <div class="d-flex flex-row align-items-center">
        <mat-form-field class="timeinput no-subscript-wrapper">
          <input
            matInput
            [ngxTimepicker]="toggleStartTimepicker"
            placeholder="10:00"
            [(ngModel)]="config.scheduledStartTime"
            processdelightTimeInput
            [format]="24"
            [disabled]="!config.scheduleTimeEnabled"
            (ngModelChange)="onChange()"
          />
          <ngx-material-timepicker-toggle
            matSuffix
            [for]="toggleStartTimepicker"
            class="timepicker-icon"
          ></ngx-material-timepicker-toggle>
          <ngx-material-timepicker
            [format]="24"
            #toggleStartTimepicker
          ></ngx-material-timepicker>
        </mat-form-field>
        <span class="px-2 my-auto">To</span>
        <mat-form-field class="timeinput no-subscript-wrapper">
          <input
            matInput
            placeholder="18:00"
            [(ngModel)]="config.scheduledEndTime"
            [ngxTimepicker]="toggleEndTimepicker"
            processdelightTimeInput
            [format]="24"
            [disabled]="!config.scheduleTimeEnabled"
            (ngModelChange)="onChange()"
          />
          <ngx-material-timepicker-toggle
            matSuffix
            [for]="toggleEndTimepicker"
            class="timepicker-icon"
          ></ngx-material-timepicker-toggle>
          <ngx-material-timepicker
            [format]="24"
            timepickerClass="timepickerClass"
            #toggleEndTimepicker
          ></ngx-material-timepicker>
        </mat-form-field>
      </div>
    </div>
    <div class="configrow">
      <span class="my-auto mx-4 text-end"> And never on </span>
      <div>
        <mat-button-toggle-group
          name="ingredients"
          [(value)]="selectedDays"
          (change)="onChange()"
          aria-label="Ingredients"
          multiple
        >
          <mat-button-toggle
            *ngFor="let day of days; trackBy: dayTrackBy"
            [value]="day.id"
            >{{ day.abbreviation }}</mat-button-toggle
          >
        </mat-button-toggle-group>
      </div>
    </div>
  </div>
  <div class="mt-3">
    <div class="d-flex flex-column">
      <div class="categoryrow">
        <h4>
          {{ translations.getTranslation$("notifications") | async }}
        </h4>
        <span class="text-center">Mail</span>
      </div>
      <div
        *ngFor="let config of config.notificationConfigs"
        class="categoryrow"
      >
        <p class="m-0">{{ config.category?.name }}</p>
        <mat-checkbox
          [checked]="config.mailEnabled"
          (change)="config.mailEnabled = $event.checked; onChange()"
        ></mat-checkbox>
      </div>
    </div>
  </div>
</div>

<div
  *ngIf="!config"
  class="h-100 w-100 d-flex flex-column align-items-center justify-content-center"
>
  <mat-spinner />
</div>
