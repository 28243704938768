import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import {
  Component,
  ElementRef,
  inject,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChildren,
} from '@angular/core';
import {
  FormArray,
  FormControl,
  FormGroup,
  NonNullableFormBuilder,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { NgxColorsModule } from 'ngx-colors';
import {
  ContextMenuAction,
  LoaderService,
} from 'processdelight-angular-components';
import {
  BehaviorSubject,
  catchError,
  combineLatest,
  first,
  forkJoin,
  map,
  Observable,
  of,
  startWith,
  Subject,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs';
import { InterestGroupSubgroup } from 'src/app/core/domain/models/interest-group-subgroup.model';
import { InterestGroup } from 'src/app/core/domain/models/interest-group.model';
import { Ishtar365ActionsService } from 'src/app/core/services/ishtar365-actions.service';
import { Ishtar365Service } from 'src/app/core/services/ishtar365.service';
import { TranslationService } from 'src/app/core/services/translation.service';
import { InterestGroupFacade } from 'src/app/core/store/interest-group/interest-group.facade';
import { MatTreeModule, MatTreeNestedDataSource } from '@angular/material/tree';
import * as uuid from 'uuid';
import { NestedTreeControl } from '@angular/cdk/tree';
import { MatTooltipModule } from '@angular/material/tooltip';

interface Groups {
  id: string;
  name: string;
  children?: Groups[];
}

@Component({
  selector: 'app-interest-groups',
  templateUrl: './interest-groups.component.html',
  styleUrls: ['./interest-groups.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatCardModule,
    MatIconModule,
    MatButtonModule,
    MatListModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    NgxColorsModule,
    DragDropModule,
    MatMenuModule,
    MatTreeModule,
    MatTooltipModule,
  ],
})
export class InterestGroupsComponent implements OnInit, OnDestroy {
  interestGroups?: FormArray<FormGroup>;
  activeInterestGroup?: FormGroup;
  joinableInterestGroups: InterestGroup[] = [];
  possibleSubGroups: InterestGroup[] = [];

  interestGroupsToDelete = new BehaviorSubject<string[]>([]);
  isSubmitEnabled$: Observable<boolean> = of(false);

  treeControl = new NestedTreeControl<Groups>((node) => node.children ?? []);
  dataSource = new MatTreeNestedDataSource<Groups>();

  private readonly nonNullBuilder = inject(NonNullableFormBuilder);
  protected readonly filterCommunitiesControl = this.nonNullBuilder.control<string>('');
  protected filteredCommunities: FormGroup[] = [];

  @ViewChildren('titleInterestGroup') titleInputs?: QueryList<
    ElementRef<HTMLInputElement>
  >;

  hasChild = (_: number, node: Groups) =>
    !!node.children && node.children.length > 0;

  groupTrackByFn(index: number, item: InterestGroup) {
    return item.id;
  }

  constructor(
    private interestGroupFacade: InterestGroupFacade,
    private ishtar365ActionService: Ishtar365ActionsService,
    private readonly translations: TranslationService,
    private readonly sanitizer: DomSanitizer,
    private readonly loader: LoaderService,
    private readonly ishtar365: Ishtar365Service
  ) {}

  getTranslation$(label: string) {
    return this.translations.getTranslation$(label);
  }

  ngOnInit(): void {
    this.interestGroupFacade.interestGroups$
      .pipe(takeUntil(this.destroy$))
      .subscribe((groups) => {
        if (groups) this.joinableInterestGroups = groups;
        this.interestGroups = new FormArray(
          [...groups]
            .sort((g1, g2) => g1.title.localeCompare(g2.title))
            .map(
              (g) =>
                new FormGroup({
                  new: new FormControl(false),
                  id: new FormControl(g.id),
                  title: new FormControl(g.title, [
                    Validators.required,
                    Validators.maxLength(30),
                  ]),
                  description: new FormControl(g.description),
                  icon: new FormControl(g.icon),
                  iconBlob: new FormControl<Blob | undefined>(undefined),
                  oldIcon: new FormControl(g.icon),
                  color: new FormControl(g.color),
                  subGroups: new FormControl(
                    g.subGroups ? [...g.subGroups] : []
                  ),
                })
            )
        );

        this.filteredCommunities = this.interestGroups.controls ?? [];
        this.isSubmitEnabled$ = combineLatest([
          this.interestGroups!.valueChanges.pipe(
            startWith(this.interestGroups?.value ?? [])
          ),
          this.interestGroupsToDelete,
        ]).pipe(
          map(([interestGroups, interestGroupsToDelete]) => {
            return (
              this.interestGroups!.controls.every((c) =>
                this.isInterestGroupValid(c)
              ) &&
              (this.interestGroups!.controls.some((c) => c.dirty) ||
                interestGroupsToDelete.length > 0)
            );
          })
        );
        this.ishtar365ActionService.buttonActions = [
          new ContextMenuAction<unknown>({
            label: 'Save',
            action: () => this.submit(),
            disabled: this.isSubmitEnabled$.pipe(map((b) => !b)),
            icon: 'save',
            iconOutline: true,
          }),
        ];
      });
  }

  protected filterCommunities(): void {
		const filterValue = this.filterCommunitiesControl.value?.toLowerCase() || '';
    if (!this.interestGroups) return;
    this.filteredCommunities = this.interestGroups.controls
      .filter((ctrl) => this.getTitle(ctrl as FormGroup)
        .toLowerCase().includes(filterValue)) as FormGroup[];
	}

  destroy$ = new Subject<void>();
  ngOnDestroy(): void {
    this.interestGroupsToDelete.complete();
    this.ishtar365ActionService.buttonActions = [];
    this.destroy$.next();
    this.destroy$.complete();
  }

  private blobToBase64(blob: Blob) {
    const subject = new Subject<string>();
    const fr = new FileReader();
    fr.onloadend = () => {
      subject.next(fr.result as string);
      subject.complete();
    };
    fr.readAsDataURL(blob);
    return subject.asObservable();
  }

  getId(group?: FormGroup) {
    return group?.controls.id.value as string;
  }
  getTitle(group?: FormGroup) {
    return group?.controls.title.value as string;
  }
  getTitleControl(group?: FormGroup) {
    return group?.controls.title as FormControl<string>;
  }
  getDescription(group?: FormGroup) {
    return group?.controls.description.value as string;
  }
  getDescriptionControl(group?: FormGroup) {
    return group?.controls.description as FormControl<string>;
  }
  getIconBlobControl(group?: FormGroup) {
    return group?.controls.iconBlob as FormControl<Blob | undefined>;
  }
  getIconUrlControl(group?: FormGroup) {
    return group?.controls.icon as FormControl<string>;
  }
  getOldIconUrlControl(group?: FormGroup) {
    return group?.controls.oldIcon as FormControl<string>;
  }
  getColor(group?: FormGroup) {
    return group?.controls.color.value as string;
  }
  getColorControl(group?: FormGroup) {
    return group?.controls.color as FormControl<string>;
  }
  getSubGroups(group?: FormGroup) {
    return group?.controls.subGroups as FormControl<InterestGroupSubgroup[]>;
  }

  addInterestGroup() {
    const newInterestGroup = new FormGroup({
      new: new FormControl(true),
      id: new FormControl(uuid.v4()),
      title: new FormControl('', [
        Validators.required,
        Validators.maxLength(30),
      ]),
      description: new FormControl(''),
      icon: new FormControl(''),
      iconBlob: new FormControl<Blob | undefined>(undefined),
      oldIcon: new FormControl(undefined),
      color: new FormControl('#FF2211'),
      subGroups: new FormControl([]),
    });
    this.interestGroups?.push(newInterestGroup);
    this.selectInterestGroup(newInterestGroup);
    this.activeIconUrl = undefined;
    setTimeout(() => {
      this.titleInputs?.last?.nativeElement?.focus();
    }, 0);
  }

  addSubGroup(interestGroup: InterestGroup) {
    this.getSubGroups(this.activeInterestGroup).setValue([
      ...this.getSubGroups(this.activeInterestGroup).value,
      new InterestGroupSubgroup({
        id: uuid.v4(),
        subGroupId: interestGroup.id,
        subGroup: interestGroup,
        parentGroupId: this.getId(this.activeInterestGroup),
      }),
    ]);
    this.activeInterestGroup?.markAsDirty();
    this.possibleSubGroups = this.getJoinableInterestGroups();
    this.dataSource.data = this.getConvertedNodes(this.activeInterestGroup!);
  }

  removeSubGroup(subGroupId: string) {
    this.activeInterestGroup?.markAsDirty();
    this.getSubGroups(this.activeInterestGroup).setValue(
      this.getSubGroups(this.activeInterestGroup).value.filter(
        (group) => group.id !== subGroupId
      )
    );
    this.possibleSubGroups = this.getJoinableInterestGroups();
    this.dataSource.data = this.getConvertedNodes(this.activeInterestGroup!);
  }

  selectInterestGroup(group?: FormGroup) {
    this.activeInterestGroup = group;
    if (group && this.getIconUrlControl(group).value) {
      this.activeIconUrl = this.transform(this.getIconUrlControl(group).value);
    } else {
      this.activeIconUrl = undefined;
    }
    if (group) this.possibleSubGroups = this.getJoinableInterestGroups();
    this.dataSource.data = this.getConvertedNodes(this.activeInterestGroup!);
  }

  toggleDeleteInterestGroup(group: FormGroup) {
    const pagesToDeleteArray = this.interestGroupsToDelete.value;
    if (this.isInterestGroupDeleted(group))
      this.interestGroupsToDelete.next(
        pagesToDeleteArray.filter((id) => id != this.getId(group))
      );
    else {
      pagesToDeleteArray.push(this.getId(group));
      this.interestGroupsToDelete.next(pagesToDeleteArray);
    }
    this.dataSource.data = this.getConvertedNodes(this.activeInterestGroup!);
  }
  isInterestGroupDeleted(group: FormGroup) {
    return this.interestGroupsToDelete.value.includes(this.getId(group));
  }
  isInterestGroupValid(group: FormGroup) {
    return !this.isInterestGroupDeleted(group)
      ? this.getTitleControl(group).valid
      : true;
  }

  getJoinableInterestGroups() {
    const interestGroupsToDelete = this.interestGroupsToDelete.value;
    const existingSubGroups = this.getSubGroups(
      this.activeInterestGroup
    ).value.map((s) => s.subGroupId);
    const blackListedPageIds: string[] = [
      this.getId(this.activeInterestGroup),
      ...interestGroupsToDelete,
      ...existingSubGroups,
    ];
    if (this.interestGroups) {
      let newBlackListed: string[] = [];
      do {
        newBlackListed = [];
        for (const interestGroup of this.interestGroups.controls.filter(
          (t) => !blackListedPageIds.includes(this.getId(t))
        ))
          for (const subGroup of this.getSubGroups(interestGroup).value.filter(
            (t) => blackListedPageIds.includes(t.subGroupId)
          )) {
            newBlackListed.push(this.getId(interestGroup));
          }

        blackListedPageIds.push(...newBlackListed);
      } while (newBlackListed.length);
    }
    return (
      this.interestGroups?.controls
        .filter((t) => !blackListedPageIds.includes(this.getId(t)))
        .map((g) => {
          return new InterestGroup({
            id: this.getId(g as FormGroup),
            title: this.getTitle(g as FormGroup),
            description: this.getDescription(g as FormGroup),
            subGroups: this.getSubGroups(g as FormGroup).value,
          });
        }) ?? []
    );
  }
  getConvertedNodes(formGroup: FormGroup): Groups[] {
    const r = this.getSubGroups(formGroup).value.map((subGroup) => {
      return {
        id: subGroup.id,
        name: this.interestGroups?.controls.find(
          (g) => this.getId(g as FormGroup) == subGroup.subGroupId
        )?.value.title, // to get the update title of the subGroup if changes were made before saving
        children: this.getConvertedNodes(
          this.interestGroups?.controls.find(
            (g) => this.getId(g as FormGroup) == subGroup.subGroupId
          ) as FormGroup
        ),
      };
    });
    return r.sort((a, b) => a.name.localeCompare(b.name));
  }

  submit() {
    this.isSubmitEnabled$.pipe(first()).subscribe((b) => {
      //find a way to delete the icons
      if (b) {
        const groupToInterestGroup = (group: FormGroup) =>
          this.getIconBlobControl(group).value
            ? this.blobToBase64(this.getIconBlobControl(group).value!).pipe(
                switchMap((b64) => {
                  const oldIconUrl = this.getOldIconUrlControl(group)?.value;
                  const upload = this.ishtar365.uploadInterestGroupIcon(
                    this.getId(group),
                    (this.getIconBlobControl(group).value as File).name
                      .split('.')
                      .pop() ?? 'png',
                    b64
                  );
                  return oldIconUrl
                    ? this.ishtar365
                        .deleteTileIcon(this.getOldIconUrlControl(group).value)
                        .pipe(
                          switchMap(() => upload),
                          catchError(() => upload)
                        )
                    : upload;
                }),
                map(
                  (iconUrl) =>
                    new InterestGroup({
                      id: this.getId(group),
                      title: this.getTitle(group),
                      description: this.getDescription(group),
                      icon: iconUrl,
                      color: this.getColor(group),
                      subGroups: this.getSubGroups(group).value
                        ? this.getSubGroups(group).value.map(
                            (s) =>
                              new InterestGroupSubgroup({
                                ...s,
                                subGroup: undefined,
                              })
                          )
                        : [],
                    })
                )
              )
            : of(
                new InterestGroup({
                  id: this.getId(group),
                  title: this.getTitle(group),
                  description: this.getDescription(group),
                  icon: this.getIconUrlControl(group).value,
                  color: this.getColor(group),
                  subGroups: this.getSubGroups(group).value
                    ? this.getSubGroups(group).value.map(
                        (s) =>
                          new InterestGroupSubgroup({
                            ...s,
                            subGroup: undefined,
                          })
                      )
                    : [],
                })
              );
        this.getTranslation$('savingConfiguration')
          .pipe(first())
          .subscribe((label) =>
            this.loader.startLoading(label, () =>
              forkJoin(
                this.interestGroups!.controls.filter(
                  (s) => !this.isInterestGroupDeleted(s as FormGroup)
                ).map((p) => groupToInterestGroup(p as FormGroup))
              ).pipe(
                switchMap((groups) =>
                  this.interestGroupFacade.updateInterestGroup(groups).pipe(
                    tap((x) => {
                      this.activeInterestGroup = undefined;
                    })
                  )
                )
              )
            )
          );
      }
    });
  }

  //icon

  dragging = false;

  dragStart(event: Event) {
    event.preventDefault();
    event.stopPropagation();
    if (this.dragging) return;
    this.dragging = true;
  }

  dragStop(event: Event) {
    event.preventDefault();
    event.stopPropagation();
    if (!this.dragging) return;
    this.dragging = false;
  }

  imageDropped(event: DragEvent, group: FormGroup) {
    event.preventDefault();
    event.stopPropagation();
    this.dragging = false;
    if (event.dataTransfer?.files && event.dataTransfer.files.length) {
      const file = event.dataTransfer.files[0];

      this.getIconBlobControl(group).patchValue(file);
      this.updateIconBlobUrl(group);
    }
  }

  activeIconUrl?: SafeHtml;

  transform(value: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustResourceUrl(value);
  }

  onIconInputChange(event: Event, group: FormGroup) {
    const input = event.target as HTMLInputElement;

    if (input?.files && input.files.length) {
      const file = input.files[0];

      this.getIconBlobControl(group).patchValue(file);
      this.updateIconBlobUrl(group);
      input.value = '';
    }
  }

  clearIconInput(group: FormGroup) {
    group.markAsDirty();
    this.getIconBlobControl(group).patchValue(undefined);
    this.getIconUrlControl(group).patchValue('');
    this.updateIconBlobUrl(group);
  }

  updateIconBlobUrl(group: FormGroup) {
    const iconUrlControl = this.getIconUrlControl(group);
    if (iconUrlControl.value) {
      URL.revokeObjectURL(iconUrlControl.value);
      iconUrlControl.patchValue('');
    }
    const iconBlobControl = this.getIconBlobControl(group);
    iconBlobControl.markAsDirty();
    if (iconBlobControl.value)
      iconUrlControl.patchValue(URL.createObjectURL(iconBlobControl.value));
    this.activeIconUrl = iconUrlControl.value
      ? this.transform(iconUrlControl.value)
      : undefined;
  }
}
