import { Action, createReducer, on } from '@ngrx/store';
import { getFormTemplatesResolved } from './formTemplate.actions';
import { DataRecord } from 'processdelight-angular-components';

export const featureSlice = 'formTemplate';

export interface State {
  formTemplates: {
    [appName: string]: DataRecord[];
  };
}

const defaultState: State = {
  formTemplates: {},
};

export function Reducer(state: State | undefined, action: Action) {
  return formTemplateReducer(state, action);
}

export const initialState: State = defaultState;
export const formTemplateReducer = createReducer(
  initialState,
  on(getFormTemplatesResolved, (state, { appName, formTemplates }) => ({
    ...state,
    formTemplates: {
      ...state.formTemplates,
      [appName]: formTemplates,
    },
  }))
);
