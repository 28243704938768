<div class="top-bar-wrapper">
  <div
    *ngIf="signedIn"
    (mouseenter)="onCandyBoxEnter()"
    (mouseleave)="onCandyBoxLeave()"
    (click)="onCandyBoxClicked()"
    class="candybox d-flex align-items-center justify-content-center"
  >
    <img src="../../../assets/Candy_Menu_Ishtar.svg" alt="Candybox" />
  </div>
  <div
    class="horizontal-scrollbar h-100 overflow-x-auto overflow-y-hidden"
    *ngIf="!isMobile$.value"
  >
    <mat-button-toggle-group
      *ngIf="{
        activeApp: activeApp$ | async
      } as appData"
      [value]="appData.activeApp?.appName ?? 'Ishtar.365'"
      style="transform: translateY(-3px)"
    >
      <mat-button-toggle
        *ngFor="let app of openApps"
        [value]="app.appName"
        class="ishtar-tabs"
        (click)="handleTabChange(app.appName)"
      >
        <img
          width="32"
          height="32"
          [src]="app.appIconUrl"
          alt="{{ app.appName }} Logo"
        />
        <div
          class="title"
          *ngIf="{
            isTabExpanded: isTabExpanded(
              app.appName,
              appData.activeApp ?? undefined
            )
          } as tabData"
          [style.width]="tabData.isTabExpanded ? 'auto' : '0'"
          [style.margin-left]="tabData.isTabExpanded ? '1rem' : '0'"
          [style.opacity]="tabData.isTabExpanded ? '1' : '0'"
        >
          {{ app.appName }}
          <button
            *ngIf="app.appName !== 'Ishtar.365'"
            mat-icon-button
            (click)="closeApp(app.appName); $event.stopPropagation()"
          >
            <mat-icon>close</mat-icon>
          </button>
        </div>
      </mat-button-toggle>
    </mat-button-toggle-group>
  </div>
  <button
    *ngIf="closedApps.length && !isMobile$.value"
    mat-icon-button
    [matMenuTriggerFor]="openNewTabMenu"
  >
    <mat-icon>add</mat-icon>
  </button>
  <mat-menu #openNewTabMenu>
    <mat-action-list>
      <mat-list-item
        *ngFor="let app of closedApps"
        (click)="openApp(app.appName)"
      >
        <div class="d-flex flex-row">
          <img
            width="32"
            height="32"
            [src]="app.appIconUrl"
            alt="{{ app.appName }} Logo"
          />
          <div class="d-flex align-items-center ps-2">
            {{ app.appName }}
          </div>
        </div>
      </mat-list-item>
    </mat-action-list>
  </mat-menu>
  <div *ngIf="signedIn && { activeApp: activeApp$ | async } as appData">
    <button
      mat-icon-button
      [matMenuTriggerFor]="mobileMenu"
      (menuOpened)="menuOpen = true"
      (menuClosed)="menuOpen = false"
      *ngIf="isMobile$.value"
      class="app-dropdown open-app"
    >
      <div class="d-flex align-items-center justify-content-between h-100">
        <img
          class="d-flex flex-row app-image"
          [src]="appData.activeApp?.appIconUrl"
          alt="{{ appData.activeApp?.appName }}"
        />
        <div class="d-flex align-items-center ps-2 active-app-title">
          {{ appData.activeApp?.appName }}
        </div>
        <mat-icon class="menu-indicator">{{
          menuOpen ? "expand_less" : "expand_more"
        }}</mat-icon>
      </div>
    </button>

    <mat-menu #mobileMenu="matMenu" class="scrollbar">
      <button
        mat-menu-item
        *ngFor="let app of openApps"
        (click)="handleTabChange(app.appName)"
        class="menu-button open-app"
      >
        <img
          width="32"
          height="32"
          [src]="app.appIconUrl"
          alt="{{ app.appName }} Logo"
        />
        <div class="ms-2">{{ app.appName }}</div>
        <button
          *ngIf="app.appName !== 'Ishtar.365'"
          mat-icon-button
          (click)="closeApp(app.appName); $event.stopPropagation()"
          class="close-button"
        >
          <mat-icon class="margin-left">close</mat-icon>
        </button>
      </button>

      <button
        mat-menu-item
        *ngFor="let app of closedApps"
        (click)="openApp(app.appName)"
        class="menu-button"
      >
        <img
          width="32"
          height="32"
          [src]="app.appIconUrl"
          alt="{{ app.appName }} Logo"
        />
        <div class="ms-2">{{ app.appName }}</div>
      </button>
    </mat-menu>
  </div>
  <div class="spacer"></div>
  <div class="account">
    <ng-container *ngIf="signedIn; else signInButton">
      <a
        *ngIf="signedIn && !isMobile$.value"
        mat-icon-button
        [title]="getTranslation$('openDocumentation') | async"
        href="https://knowledge.ishtar365.com/knowledge"
        target="_blank"
      >
        <mat-icon class="material-icons-outlined">help_outline</mat-icon>
      </a>
      <processdelight-notification-button
        [resizeBarColor]="navColor"
        *ngIf="!isMobile$.value"
        [hidePin]="true"
        [hideDockSwitcher]="true"
        [customWidth]="300"
      ></processdelight-notification-button>
      <div
        class="h-100 ps-2 d-flex flex-row align-items-center clickable account-menu-button"
        (click)="accountMenuTrigger.toggleMenu()"
      >
        <ng-container *ngIf="{ isMobile: isMobile$ | async } as data">
          <div class="name" *ngIf="username && !data.isMobile">
            {{ username }}
          </div>
        </ng-container>
        <div class="photo" *ngIf="userPhoto; else initials">
          <img [src]="userPhoto" width="32" height="32" alt="user image" />
        </div>
        <ng-template #initials>
          <processdelight-user-initials
            *ngIf="username"
            [userName]="username"
            class="mx-2"
          ></processdelight-user-initials>
        </ng-template>
      </div>
      <div
        #accountMenuTrigger="matMenuTrigger"
        [matMenuTriggerFor]="accountMenu"
        class="align-self-end"
      ></div>
      <mat-menu #accountMenu class="overflow-hidden">
        <div
          class="d-flex flex-column mb-1 px-3"
          [style.margin-top.px]="isMobile$.value ? 0 : 8"
        >
          <div
            class="d-flex flex-row justify-content-between"
            *ngIf="isMobile$.value"
          >
            <a
              *ngIf="signedIn"
              mat-icon-button
              [title]="getTranslation$('openDocumentation') | async"
              href="https://knowledge.ishtar365.com/knowledge"
              target="_blank"
            >
              <mat-icon class="material-icons-outlined">help_outline</mat-icon>
            </a>
            <processdelight-notification-button
              [resizeBarColor]="navColor"
              [parent]="parent"
              [customWidth]="bodyWidth"
              [hideDockSwitcher]="true"
              [hidePin]="true"
            ></processdelight-notification-button>
            <button
              *ngIf="signedIn && (isAdmin$ | async)"
              mat-icon-button
              (click)="
                getUrl().startsWith('/settings') ? goToHome() : goToSettings()
              "
              [title]="
                getTranslation$(
                  getUrl().startsWith('/settings') ? 'home' : 'settings'
                ) | async
              "
            >
              <mat-icon class="material-icons-outlined">
                {{ getUrl().startsWith("/settings") ? "home" : "settings" }}
              </mat-icon>
            </button>
          </div>
          <span class="bold">{{ username }}</span>
          <span class="mb-2">{{ userMail }}</span>
          <div class="d-flex flex-column">
            <div
              class="bold"
              *ngIf="
                getTranslation$('environments') | async as environmentsLabel
              "
            >
              {{ environmentsLabel }}:
            </div>
            <mat-action-list class="tenant-picker">
              <mat-list-item
                *ngFor="let tenant of tenants"
                (click)="
                  tenant.tenantId === selectedTenant?.tenantId
                    ? $event.stopPropagation()
                    : selectTenant(tenant, true)
                "
                [class.active]="tenant.tenantId === selectedTenant?.tenantId"
                [style.height.rem]="2"
              >
                {{ tenant.displayName }}
              </mat-list-item>
            </mat-action-list>
          </div>
          <div
            class="mb-2 d-flex flex-row align-items-center"
            *ngIf="userLang"
            (click)="$event.stopPropagation()"
          >
            <div
              class="me-2 bold"
              *ngIf="getTranslation$('language') | async as languageLabel"
            >
              {{ languageLabel }}:
            </div>
            <mat-select
              color="primary"
              [value]="userLang"
              (selectionChange)="languageChange($event)"
            >
              <mat-option value="en">English</mat-option>
              <mat-option value="nl">Nederlands</mat-option>
              <mat-option value="fr">Français</mat-option>
            </mat-select>
          </div>
          <div class="d-flex flex-row justify-content-between align-items-center button-group">
            <button mat-button (click)="openUserSettings()">
              <span
                *ngIf="getTranslation$('settings') | async as settingsLabel"
              >
                {{ settingsLabel }}
              </span>
              <mat-icon matIconSuffix>settings</mat-icon>
            </button>
            <button mat-button (click)="signOut()">
              <span *ngIf="getTranslation$('signOut') | async as signOutLabel">
                {{ signOutLabel }}
              </span>
              <mat-icon matIconSuffix color="warn">logout</mat-icon>
            </button>
          </div>
        </div>
      </mat-menu>
    </ng-container>
    <ng-template #signInButton>
      <button mat-button (click)="signIn()">
        <mat-icon>login</mat-icon>
        Sign in
      </button>
    </ng-template>
  </div>
  <div class="tenant-logo" *ngIf="orgLogo && !isMobile$.value">
    <div class="vertical-ruler"></div>
    <div class="logo-wrapper">
      <img
        [src]="orgLogo"
        alt="organization logo"
        (load)="setParentWidth($event)"
      />
    </div>
  </div>
  <button
    *ngIf="signedIn && (isAdmin$ | async) && !isMobile$.value"
    mat-icon-button
    (click)="goToSettings()"
    [title]="getTranslation$('settings') | async"
  >
    <mat-icon class="material-icons-outlined">settings</mat-icon>
  </button>
</div>
