import { JsonHubProtocol } from '@microsoft/signalr';
import {
  Application,
  GroupUser,
  GroupUserEntity,
} from 'processdelight-angular-components';

export class SendNotification {
  id!: string;
  tenantId?: string;

  groupUserId?: string;
  portalUserId?: string;
  email?: string;

  title!: string;
  content!: string;

  navigationKey!: string;
  navigationProperties?: { [key: string]: string };
  applicationId!: string;

  sendDate?: Date;
  scheduledSendDate?: Date;
  dismissedDate?: Date;

  groupUser?: GroupUserEntity;
  portalUser?: any; // not important for i365
  application!: Application;

  constructor(obj?: Partial<SendNotification>) {
    Object.assign(this, obj);
    if (typeof obj?.navigationProperties === 'string') {
      try {
        this.navigationProperties = JSON.parse(obj.navigationProperties);
      } catch {
        this.navigationProperties = undefined;
      }
    }
  }
}
