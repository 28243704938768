import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  FormArray,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';
import {
  ContextMenuAction,
  GroupUser,
} from 'processdelight-angular-components';
import { BehaviorSubject, catchError, Subject, takeUntil } from 'rxjs';
import {
  ArticleTypeConfiguration,
  ArticleTypeReminderReceiver,
  ArticleTypeWashingOrRepairLocation,
} from 'src/app/core/domain/models/article-types/article-type-configuration.model';
import { ArticleType } from 'src/app/core/domain/models/article-types/article-type.model';
import { WarehouseLocation } from 'src/app/core/domain/models/article-types/warehouse-location.model';
import { DataRecord } from 'processdelight-angular-components';
import { Ishtar365ActionsService } from 'src/app/core/services/ishtar365-actions.service';
import { TranslationService } from 'src/app/core/services/translation.service';
import { ArticleTypeFacade } from 'src/app/core/store/article-type/article-type.facade';
import { FormTemplateFacade } from 'src/app/core/store/formTemplate/formTemplate.facade';
import { OrganizationFacade } from 'src/app/core/store/organization/organization.facade';
import { Memoize } from 'typescript-memoize';
import * as uuid from 'uuid';

@Component({
  selector: 'app-article-type-settings',
  standalone: true,
  imports: [
    CommonModule,
    MatCardModule,
    MatListModule,
    MatButtonModule,
    MatCheckboxModule,
    MatSelectModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatSnackBarModule,
  ],
  templateUrl: './article-type-settings.component.html',
  styleUrls: ['./article-type-settings.component.scss'],
})
export class ArticleTypeSettingsComponent implements OnInit, OnDestroy {
  formArray = new FormArray<
    FormGroup<{
      isNew: FormControl<boolean | null>;
      originalConfig: FormControl<ArticleTypeConfiguration | null>;
      erpId: FormControl<number | null>;
      maxTimesOfWashingOrRepairEnabled: FormControl<boolean | null>;
      maxTimesOfWashingOrRepair: FormControl<number | null>;
      maxTimesOfWashingOrRepairLocations: FormControl<number[] | null>;
      washOrMaintenanceReminderEnabled: FormControl<boolean | null>;
      washOrMaintenanceReminder: FormControl<number | null>;
      washOrMaintenanceReminderPeople: FormControl<string[] | null>;
      isClothing: FormControl<boolean | null>;
      validationTaskTemplateEnabled: FormControl<boolean | null>;
      validationTaskTemplateId: FormControl<string | null>;
      employmentValidationTaskTemplateEnabled: FormControl<boolean | null>;
      employmentValidationTaskTemplateId: FormControl<string | null>;
      maintenanceRequestTaskTemplateEnabled: FormControl<boolean | null>;
      maintenanceRequestTaskTemplateId: FormControl<string | null>;
      repairRequestTaskTemplateEnabled: FormControl<boolean | null>;
      repairRequestTaskTemplateId: FormControl<string | null>;
      outOfServiceTaskTemplateEnabled: FormControl<boolean | null>;
      outOfServiceTaskTemplateId: FormControl<string | null>;
    }>
  >([]);

  activeArticleType?: FormGroup;

  groupUsers: GroupUser[] = [];

  warehouseLocations: WarehouseLocation[] = [];
  warehouseLocationCompareWithFn = (
    o1: WarehouseLocation,
    o2: WarehouseLocation
  ) => o1?.id === o2?.id;

  taskTemplates: DataRecord[] = [];
  taskTemplateCompareWithFn = (o1: DataRecord, o2: DataRecord) =>
    o1?.id === o2?.id;

  saveDisabled$ = new BehaviorSubject<boolean>(true);

  destroy$ = new Subject<void>();

  constructor(
    private readonly translations: TranslationService,
    private readonly articleTypeFacade: ArticleTypeFacade,
    private readonly organizationFacade: OrganizationFacade,
    private readonly formTemplateFacade: FormTemplateFacade,
    private readonly ishtar365Actions: Ishtar365ActionsService,
    private readonly snackbar: MatSnackBar
  ) {}

  ngOnInit(): void {
    this.ishtar365Actions.buttonActions = [
      new ContextMenuAction<unknown>({
        label: this.translations.getTranslation$('save'),
        icon: 'save',
        action: () => this.onSubmit(),
        disabled: this.saveDisabled$,
      }),
    ];
    this.organizationFacade.groupUsers$
      .pipe(takeUntil(this.destroy$))
      .subscribe((groupUsers) => {
        this.groupUsers = groupUsers;
      });
    this.articleTypeFacade.warehouseLocations$
      .pipe(takeUntil(this.destroy$))
      .subscribe((warehouseLocations) => {
        this.warehouseLocations = warehouseLocations;
      });
    this.formTemplateFacade
      .formTemplatesByAppName$('IshtarTasks')
      .pipe(takeUntil(this.destroy$))
      .subscribe((formTemplates) => {
        this.taskTemplates = formTemplates;
      });
    this.articleTypeFacade.articleTypeConfigurations$
      .pipe(takeUntil(this.destroy$))
      .subscribe((articleTypeConfigurations) => {
        this.formArray.clear();
        articleTypeConfigurations
          .map((c) => new ArticleTypeConfiguration(c))
          .forEach((articleTypeConfiguration) => {
            const washOrMaintenanceReminderEnabled =
              articleTypeConfiguration.washOrMaintenanceReminder != null;
            const maxTimesOfWashingOrRepairEnabled =
              articleTypeConfiguration.maxTimesOfWashingOrRepair != null;
            const validationTaskTemplateEnabled =
              articleTypeConfiguration.validationTaskTemplateId != null;
            const employmentValidationTaskTemplateEnabled =
              articleTypeConfiguration.employmentValidationTaskTemplateId !=
              null;
            const maintenanceRequestTaskTemplateEnabled =
              articleTypeConfiguration.maintenanceRequestTaskTemplateId != null;
            const repairRequestTaskTemplateEnabled =
              articleTypeConfiguration.repairRequestTaskTemplateId != null;
            const outOfServiceTaskTemplateEnabled =
              articleTypeConfiguration.outOfServiceTaskTemplateId != null;
            const newConfig = articleTypeConfiguration.id == null;
            articleTypeConfiguration.id ??= uuid.v4();
            this.formArray.push(
              new FormGroup({
                isNew: new FormControl<boolean | null>(newConfig),
                originalConfig: new FormControl<ArticleTypeConfiguration>(
                  articleTypeConfiguration
                ),
                erpId: new FormControl<number>(articleTypeConfiguration.erpId),
                maxTimesOfWashingOrRepairEnabled: new FormControl<
                  boolean | null
                >(maxTimesOfWashingOrRepairEnabled),
                maxTimesOfWashingOrRepair: new FormControl<number | null>(
                  {
                    value:
                      articleTypeConfiguration.maxTimesOfWashingOrRepair ??
                      null,
                    disabled: !maxTimesOfWashingOrRepairEnabled,
                  },
                  [Validators.min(0), Validators.required]
                ),
                maxTimesOfWashingOrRepairLocations: new FormControl<
                  number[] | null
                >(
                  {
                    value:
                      articleTypeConfiguration.articleTypeWashingOrRepairLocations?.map(
                        (l) => l.erpLocationId
                      ) ?? [],
                    disabled: !maxTimesOfWashingOrRepairEnabled,
                  },
                  [Validators.required]
                ),
                washOrMaintenanceReminderEnabled: new FormControl<
                  boolean | null
                >(washOrMaintenanceReminderEnabled),
                washOrMaintenanceReminder: new FormControl<number | null>(
                  {
                    value:
                      articleTypeConfiguration.washOrMaintenanceReminder ??
                      null,
                    disabled: !washOrMaintenanceReminderEnabled,
                  },
                  [Validators.min(0), Validators.required]
                ),
                washOrMaintenanceReminderPeople: new FormControl<
                  string[] | null
                >(
                  {
                    value:
                      articleTypeConfiguration.articleTypeReminderReceivers?.map(
                        (r) => r.receiverId
                      ) ?? [],
                    disabled: !washOrMaintenanceReminderEnabled,
                  },
                  [Validators.required]
                ),
                isClothing: new FormControl<boolean>(
                  articleTypeConfiguration.isClothing
                ),
                validationTaskTemplateEnabled: new FormControl<boolean | null>(
                  validationTaskTemplateEnabled
                ),
                validationTaskTemplateId: new FormControl<string | null>(
                  {
                    value:
                      articleTypeConfiguration.validationTaskTemplateId ?? null,
                    disabled: !validationTaskTemplateEnabled,
                  },
                  Validators.required
                ),
                employmentValidationTaskTemplateEnabled: new FormControl<
                  boolean | null
                >(employmentValidationTaskTemplateEnabled),
                employmentValidationTaskTemplateId: new FormControl<
                  string | null
                >(
                  {
                    value:
                      articleTypeConfiguration.employmentValidationTaskTemplateId ??
                      null,
                    disabled: !employmentValidationTaskTemplateEnabled,
                  },
                  Validators.required
                ),
                maintenanceRequestTaskTemplateEnabled: new FormControl<
                  boolean | null
                >(maintenanceRequestTaskTemplateEnabled),
                maintenanceRequestTaskTemplateId: new FormControl<
                  string | null
                >(
                  {
                    value:
                      articleTypeConfiguration.maintenanceRequestTaskTemplateId ??
                      null,
                    disabled: !maintenanceRequestTaskTemplateEnabled,
                  },
                  Validators.required
                ),
                repairRequestTaskTemplateEnabled: new FormControl<
                  boolean | null
                >(repairRequestTaskTemplateEnabled),
                repairRequestTaskTemplateId: new FormControl<string | null>(
                  {
                    value:
                      articleTypeConfiguration.repairRequestTaskTemplateId ??
                      null,
                    disabled: !repairRequestTaskTemplateEnabled,
                  },
                  Validators.required
                ),
                outOfServiceTaskTemplateEnabled: new FormControl<
                  boolean | null
                >(outOfServiceTaskTemplateEnabled),
                outOfServiceTaskTemplateId: new FormControl<string | null>(
                  {
                    value:
                      articleTypeConfiguration.outOfServiceTaskTemplateId ??
                      null,
                    disabled: !outOfServiceTaskTemplateEnabled,
                  },
                  Validators.required
                ),
              })
            );
          });
      });
    this.formArray.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.updateSaveDisabled();
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  getTranslation$(label: string) {
    return this.translations.getTranslation$(label);
  }

  updateSaveDisabled() {
    setTimeout(() => {
      this.saveDisabled$.next(
        !this.formArray.controls.some((c) => c.dirty) ||
          this.formArray.controls.some((c) => c.dirty && c.invalid)
      );
    }, 0);
  }

  getId(group?: FormGroup) {
    return group?.get('originalConfig')?.value?.id as string | undefined;
  }

  getArticleType(group?: FormGroup) {
    return group?.get('originalConfig')?.value?.articleType as
      | ArticleType
      | undefined;
  }

  getIsClothing(group?: FormGroup) {
    return group?.get('isClothing')?.value ?? (false as boolean);
  }

  checkDirty(group?: FormGroup, control?: string) {
    if (!group || !control) return false;
    return (group.dirty ?? false) as boolean;
  }
  checkEnabled(group?: FormGroup, control?: string) {
    if (!group || !control) return false;
    return (group.get(control)?.value ?? false) as boolean;
  }

  onEnabledChange(enabled: boolean, ...controls: string[]) {
    if (this.activeArticleType && controls.length) {
      if (enabled) {
        controls.forEach((element) => {
          this.activeArticleType?.get(element)?.enable({ emitEvent: false });
        });
      } else {
        controls.forEach((element) => {
          this.activeArticleType?.get(element)?.disable({ emitEvent: false });
        });
      }
    }
  }
  @Memoize({
    hashFunction: (warehouseLocation: WarehouseLocation) =>
      warehouseLocation.id,
  })
  warehouseLocationDisplayFn(warehouseLocation: WarehouseLocation) {
    if (warehouseLocation.warehouse)
      return `(${warehouseLocation.warehouse.description}) ${warehouseLocation.description}`;
    return warehouseLocation?.description;
  }

  onSubmit() {
    const articleTypeConfigurations = this.formArray.controls
      .filter((c) => !this.checkEnabled(c, 'isNew') || c.dirty)
      .map((c) => {
        const form = c as FormGroup;
        const config = new ArticleTypeConfiguration(
          form.get('originalConfig')?.value
        );
        const isClothing = this.checkEnabled(form, 'isClothing');
        config.isClothing = isClothing;
        if (this.checkEnabled(form, 'maxTimesOfWashingOrRepairEnabled')) {
          const maxTimesOfWashingOrRepair = form.get(
            'maxTimesOfWashingOrRepair'
          )?.value;
          config.maxTimesOfWashingOrRepair = maxTimesOfWashingOrRepair;
          const maxTimesOfWashingOrRepairLocations = form.get(
            'maxTimesOfWashingOrRepairLocations'
          )?.value as number[] | null;
          config.articleTypeWashingOrRepairLocations =
            maxTimesOfWashingOrRepairLocations?.map(
              (id) =>
                new ArticleTypeWashingOrRepairLocation({
                  erpLocationId: id,
                  articleTypeId: config.id,
                })
            );
        } else {
          config.maxTimesOfWashingOrRepair = undefined;
          config.articleTypeWashingOrRepairLocations = undefined;
        }

        if (this.checkEnabled(form, 'washOrMaintenanceReminderEnabled')) {
          const washOrMaintenanceReminder = form.get(
            'washOrMaintenanceReminder'
          )?.value;
          config.washOrMaintenanceReminder = washOrMaintenanceReminder;
          const washOrMaintenanceReminderPeople = form.get(
            'washOrMaintenanceReminderPeople'
          )?.value as string[] | null;
          config.articleTypeReminderReceivers =
            washOrMaintenanceReminderPeople?.map(
              (userId) =>
                new ArticleTypeReminderReceiver({
                  receiverId: userId,
                  typeId: config.id,
                })
            );
        } else {
          config.washOrMaintenanceReminder = undefined;
          config.articleTypeReminderReceivers = undefined;
        }

        config.validationTaskTemplate = undefined;
        if (this.checkEnabled(form, 'validationTaskTemplateEnabled')) {
          const validationTaskTemplateId = form.get(
            'validationTaskTemplateId'
          )?.value;
          config.validationTaskTemplateId = validationTaskTemplateId;
        } else {
          config.validationTaskTemplateId = undefined;
        }

        config.employmentValidationTaskTemplate = undefined;
        if (
          this.checkEnabled(form, 'employmentValidationTaskTemplateEnabled')
        ) {
          const employmentValidationTaskTemplateId = form.get(
            'employmentValidationTaskTemplateId'
          )?.value;
          config.employmentValidationTaskTemplateId =
            employmentValidationTaskTemplateId;
        } else {
          config.employmentValidationTaskTemplateId = undefined;
        }

        config.maintenanceRequestTaskTemplate = undefined;
        if (this.checkEnabled(form, 'maintenanceRequestTaskTemplateEnabled')) {
          const maintenanceRequestTaskTemplateId = form.get(
            'maintenanceRequestTaskTemplateId'
          )?.value;
          config.maintenanceRequestTaskTemplateId =
            maintenanceRequestTaskTemplateId;
        } else {
          config.maintenanceRequestTaskTemplateId = undefined;
        }

        config.repairRequestTaskTemplate = undefined;
        if (this.checkEnabled(form, 'repairRequestTaskTemplateEnabled')) {
          const repairRequestTaskTemplateId = form.get(
            'repairRequestTaskTemplateId'
          )?.value;
          config.repairRequestTaskTemplateId = repairRequestTaskTemplateId;
        } else {
          config.repairRequestTaskTemplateId = undefined;
        }

        config.outOfServiceTaskTemplate = undefined;
        if (this.checkEnabled(form, 'outOfServiceTaskTemplateEnabled')) {
          const outOfServiceTaskTemplateId = form.get(
            'outOfServiceTaskTemplateId'
          )?.value;
          config.outOfServiceTaskTemplateId = outOfServiceTaskTemplateId;
        } else {
          config.outOfServiceTaskTemplateId = undefined;
        }

        return config;
      });
    if (articleTypeConfigurations.length > 0) {
      this.articleTypeFacade
        .updateArticleTypeConfigurations$(articleTypeConfigurations)
        .pipe(
          catchError((e) => {
            this.snackbar.open(
              this.translations.getTranslation('errorSomethingWentWrong') +
                ', ' +
                this.translations.getTranslation('tryAgainLater'),
              'X',
              {
                duration: 5000,
                panelClass: 'app-notification-error',
              }
            );
            throw e;
          })
        )
        .subscribe(() => {
          this.snackbar.open(
            this.translations.getTranslation('savedArticleTypeConfiguration'),
            'X',
            {
              duration: 5000,
              panelClass: 'app-notification-success',
            }
          );
          const activeId = this.getId(this.activeArticleType);
          this.activeArticleType = undefined;
          setTimeout(() => {
            this.activeArticleType = this.formArray.controls.find(
              (c) => this.getId(c as FormGroup) === activeId
            ) as FormGroup;
          }, 0);
        });
    }
  }
}
